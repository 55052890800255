export const CLIENT = 'Client';
export const EMPLOYEE = 'Employee';
export const VENDOR = 'Vendor';
export const COUNTRY_SERVICE = 'Country Service';
export const AUTORITY = 'Authority';
export const SERVICE_CATEGORY = 'Service Category';
export const ROLE = 'Roles';
export const BONUS_LIST = 'Bonus List';
export const PRICE_LIST = 'Price List';
export const EXPENSE_LIST = 'Expense List';
export const LAB_PRICE_LIST = 'Lab Price List';
export const NEW_MFG_PRICE_LIST = 'New MFG Price List';
export const UNCONFIRMED_BILLS = 'Unconfirmed Estimate/Quotes';
export const MANAGE_PRODUCT_SERVICE = 'Manage Product/Services';
export const PROJECT_LIST = 'Project List';
export const INBOUND_CLIENT_REQUEST = 'Inbound Client Request';
export const PRODUCT_LIST = 'Product List';
export const DOCUMENT_CHECKLIST = 'Manage Document Checklist';
export const INTERNAL_RESOURCE = 'Manage Internal Resource';
export const GENERAL_SETTING = 'General Settings';
export const MANAGE_EMAIL_TEMPLATES = 'Manage Email Templates';
export const INVOICE_TRACKER = 'Invoice Tracker';
export const RENEWAL_TRACKER = 'Renewal Tracker';
export const VENDOR_PRICE_LIST = 'Vendor Price List';
export const CAMPAIGN = 'Campaign';
export const EMAIL_TEMPLATE = 'Email Templates';
