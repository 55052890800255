import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  userPermissions: localStorage.getItem('userPermissions') ? JSON.parse(localStorage.getItem('userPermissions')) : null,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('user');
      return { user: null };
    },
    setUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },
    updateUserToken: (state, action) => {
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      if (user && action.payload) {
        user.tokens = action.payload;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userPermissions', JSON.stringify(user?.permissions));
      }
      state.user = user;
      state.userPermissions = user?.permissions;
    },
    setUserPermission: (state, action) => {
      localStorage.setItem('userPermissions', JSON.stringify(action.payload));
      state.userPermissions = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser, updateUserToken, updateUserPermissions, setUserPermission } = userSlice.actions;
export const currentUser = (state) => state.userState.user;
export const currentUserPermissions = (state) => state.userState.userPermissions;
