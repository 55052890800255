import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfirmationModalProvider } from './components/ConfirmationModal';
import { DialogProvider } from './components/Dialog/DialogContext';
const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <DialogProvider>
          <ConfirmationModalProvider>
            <ToastContainer hideProgressBar={true} autoClose={2000} closeOnClick={true} pauseOnHover={false} />
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </ConfirmationModalProvider>
        </DialogProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
