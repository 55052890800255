import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    createAuthority: builder.mutation({
      query: (body) => ({
        url: 'project/configurations/authority',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateAuthority: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `project/configurations/authority/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getAuthorities: builder.mutation({
      query: ({ sortBy = 'country_id:asc', group = 1, ...params }) => ({
        url: `project/configurations/authority?sortBy=${sortBy}&group=${group}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createServiceCategory: builder.mutation({
      query: (body) => ({
        url: 'project/configurations/servicecategory',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateServiceCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `project/configurations/servicecategory/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getServiceCategories: builder.mutation({
      query: ({ sortBy = 'service_category_id:asc', findAll = 0, ...params }) => ({
        url: `project/configurations/servicecategory?&sortBy=${sortBy}&findAll=${findAll}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createServiceMapping: builder.mutation({
      query: (body) => ({
        url: 'project/configurations/servicecategorymapping',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateServiceMapping: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `project/configurations/servicecategorymapping/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getCountryServiceMapping: builder.mutation({
      query: ({ sortBy = 'country_id:asc', group = 1, ...params }) => ({
        url: `project/configurations/servicecategorymapping?sortBy=${sortBy}&group=${group}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDocChecklist: builder.mutation({
      query: ({ ...params }) => ({
        url: `project/document/getDocChecklist`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createDocChecklist: builder.mutation({
      query: (body) => ({
        url: 'project/document/createDocChecklist',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    singleDocChecklist: builder.mutation({
      query: (body) => ({
        url: 'project/document/singleDocChecklist',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    singleUpdateDocChecklist: builder.mutation({
      query: (body) => ({
        url: 'project/document/singleDocChecklist',
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDocChecklistById: builder.mutation({
      query: ({ authority_id, country_id, service_category_id, process_id }) => ({
        url: `/project/document/getDocChecklist/${authority_id}/${country_id}/${service_category_id}/${process_id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    updatDocChecklistById: builder.mutation({
      query: (body) => ({
        url: 'project/document/updateDocChecklist',
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    deleteChecklistById: builder.mutation({
      query: (body) => ({
        url: 'project/document/deleteDocChecklist',
        method: 'DELETE',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getInternalResource: builder.mutation({
      query: ({ ...params }) => ({
        url: `project/internalresource/resource`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getInternalResourceById: builder.mutation({
      query: ({ country_id, process_id, service_category_id, authority_id, group }) => ({
        url: `/project/internalresource/resource?country_id=${country_id}&process_id=${process_id}&service_category_id=${service_category_id}&authority_id=${authority_id}&group=${group}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createInternalResource: builder.mutation({
      query: (body) => ({
        url: 'project/internalresource/resource',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateInternalResourceById: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/internalresource/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteInternalResourceById: builder.mutation({
      query: (id) => ({
        url: `project/internalresource/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getProductService: builder.mutation({
      query: (params) => ({
        url: `/project/qbo/qboServiceSwMapping`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getProductServiceById: builder.mutation({
      query: (id) => ({
        url: `/project/qbo/qboServiceSwMapping/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createProductService: builder.mutation({
      query: (body) => ({
        url: `/project/qbo/qboServiceSwMapping`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateProductService: builder.mutation({
      query: ({ id, body }) => ({
        url: `/project/qbo/qboServiceSwMapping/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteProductService: builder.mutation({
      query: (id) => ({
        url: `/project/qbo/qboServiceSwMapping/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useCreateAuthorityMutation,
  useUpdateAuthorityMutation,
  useGetAuthoritiesMutation,
  useCreateServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
  useGetServiceCategoriesMutation,
  useCreateServiceMappingMutation,
  useUpdateServiceMappingMutation,
  useGetCountryServiceMappingMutation,
  useGetDocChecklistMutation,
  useCreateDocChecklistMutation,
  useGetDocChecklistByIdMutation,
  useUpdatDocChecklistByIdMutation,
  useDeleteChecklistByIdMutation,
  useSingleDocChecklistMutation,
  useSingleUpdateDocChecklistMutation,
  useGetInternalResourceMutation,
  useGetInternalResourceByIdMutation,
  useCreateInternalResourceMutation,
  useDeleteInternalResourceByIdMutation,
  useUpdateInternalResourceByIdMutation,
  useGetProductServiceMutation,
  useGetProductServiceByIdMutation,
  useCreateProductServiceMutation,
  useUpdateProductServiceMutation,
  useDeleteProductServiceMutation,
} = settingsApi;
