import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { useGetNotificationListMutation, useUpdateNotificationStatusMutation } from '../../redux/api/generalApi';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';

const Notifications = ({ refreshCount }) => {
  const { user } = useAuth();
  const containerRef = useRef(null);
  const [getNotificationList] = useGetNotificationListMutation();
  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();
  const readUnReacNotification = async (params) => {
    await updateNotificationStatus(params);
    refetchFun();
    refreshCount();
  };
  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch: refetchFun,
  } = useInfiniteQuery({
    queryKey: [`notifications-${user?.user_id}`],
    queryFn: async ({ pageParam = 0 }) => {
      let params = { page: pageParam + 1, size: 10 };
      let fetchedData = await getNotificationList(params);
      return fetchedData?.data?.data;
    },
    getNextPageParam: (_lastGroup, groups) => groups?.length,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    fetchPolicy: 'no-cache',
  });

  const flatData = useMemo(() => data?.pages.flatMap((page) => page?.results) ?? [], [data]);
  const allViewed = flatData.every((notification) => notification.is_viewed);
  const totalDBRowCount = data?.pages[0]?.totalResults;
  const totalFetched = flatData?.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can

        if (scrollHeight - scrollTop - clientHeight < 200 && !isFetching && totalFetched < totalDBRowCount) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );
  useEffect(() => {
    fetchMoreOnBottomReached(containerRef.current);
  }, [fetchMoreOnBottomReached]);

  const renderNotificationContent = () => {
    let messagesList = {};
    flatData.forEach((item) => {
      const groupTitle =
        moment(item.createdAt).format('L') === moment().format('L')
          ? 'Today'
          : moment(item.createdAt).format('L') === moment().subtract(1, 'day').format('L')
          ? 'Yesterday'
          : 'Older';

      if (!messagesList[groupTitle]) {
        messagesList[groupTitle] = [];
      }
      if (!messagesList[groupTitle].includes(item)) {
        messagesList[groupTitle].push(item);
      }
    });

    return (
      messagesList &&
      Object.keys(messagesList).map((key, idx) => (
        <div key={idx}>
          <div className="d-flex justify-content-between">
            <p className="text-uppercase fs-6 text-black-50 mb-2 ps-2 align-self-center">{key}</p>
          </div>
          {messagesList[key]?.map((noti, idxx) => (
            <div className="d-flex border-bottom border-light-subtle pb-2 mb-1 cursor-pointer" key={idxx}>
              <div className="flex-grow-1 ps-3 align-self-center">
                <div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0 fs-6 ps-2 align-self-center">{noti?.title}</p>
                    {!noti?.is_viewed && (
                      <p
                        className="position-relative translate-middle p-1 me-2 bg-primary border border-light rounded-circle notification-read-unread"
                        onClick={() =>
                          readUnReacNotification({
                            notification_id: noti?.notification_id,
                          })
                        }
                      >
                        <span className="visually-hidden"></span>
                      </p>
                    )}
                  </div>
                  <p className="text-muted mb-0 fs-6 ps-2 pe-5 align-self-center small">{noti?.message}</p>
                </div>
                <span className="text-muted mb-0 small align-self-center notification-time text-end">
                  {moment(noti.createdAt).format('DD MMM, YYYY hh:mm A')}
                </span>
              </div>
            </div>
          ))}
        </div>
      ))
    );
  };

  return (
    <div className="m-md-2 my-5">
      {!isFetching && flatData?.length === 0 ? (
        <div className="text-center">There are no notification so for !</div>
      ) : (
        <div id="con">
          <div className="d-flex justify-content-between px-2 align-items-center align-self-center border-bottom mb-2">
            <p className="text-uppercase fs-6 text-black-50 mb-2 ps-2">Notifications</p>
            {!allViewed && (
              <p
                className="cursor-pointer link small text-primary"
                onClick={() =>
                  readUnReacNotification({
                    viewedAll: !allViewed,
                  })
                }
              >
                Mark all as read
              </p>
            )}
          </div>
          <div className="notification-menu" ref={containerRef} onScroll={(e) => fetchMoreOnBottomReached(e.target)}>
            {renderNotificationContent()}
          </div>
          {isFetching && !isFetchingNextPage && (
            <div className="text-center">
              <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              Loading...
            </div>
          )}
          {isFetchingNextPage && (
            <div className="text-center">
              <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading more...</span>
              </div>
              Loading more...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
