import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import { useLogoutUserMutation } from '../../redux/api/authApi';
import { Badge, Dropdown } from 'react-bootstrap';
import Notifications from './Notifications';
import { useGetNotificationListMutation } from '../../redux/api/generalApi';
function Header() {
  const { user } = useAuth();
  const [logout, { isLoading, isError, error, isSuccess, data }] = useLogoutUserMutation();

  const [getNotificationList, { data: { unreadNotificationCount = 0 } = {} }] = useGetNotificationListMutation();

  useEffect(() => {
    refreshCount();
  }, []);
  const refreshCount = () => {
    getNotificationList({
      unreadNotificationCount: 1,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }
    if (isError) {
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) => toast.error(el.message));
      } else {
        toast.error(error.data.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleLogout = () => {
    logout({ refreshToken: JSON.parse(localStorage.getItem('user')).tokens.refresh.token });
  };

  return (
    <>
      <div className="container-fluid bg-secondary rounded-bottom-5 d-sm-block d-none">
        <div className="top-header d-flex justify-content-end align-items-center">
          <Dropdown className="mx-2">
            <Dropdown.Toggle variant="default" id="dropdown-basic" className="notification-icon text-light p-0 m-0 border-0">
              <span className="cursor-pointer profile ms-auto d-flex justify-content-center align-items-center align-self-center">
                <img src="/assets/images/bell.svg" alt="notification" />
              </span>
            </Dropdown.Toggle>

            {unreadNotificationCount > 0 && (
              <Badge pill className="position-absolute text-end translate-middle bg-danger badge-count">
                {unreadNotificationCount}
              </Badge>
            )}

            <Dropdown.Menu className="p-0">
              <Notifications refreshCount={refreshCount} />
            </Dropdown.Menu>
          </Dropdown>

          <div className="dropdown d-flex mx-2">
            <div
              className="cursor-pointer text-white dropdown-toggle d-flex justify-content-center align-items-center align-self-center"
              id="profile-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <small className="profile d-flex justify-content-center align-items-center align-self-center text-secondary fw-medium">
                {user?.first_name?.charAt(0)}
                {user?.last_name?.charAt(0)}
              </small>
            </div>
            <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="profile-dropdown">
              <li>
                <Link className="dropdown-item" to={'/profile'}>
                  Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={'/auth/change-password'}>
                  Change Password
                </Link>
              </li>
              <li onClick={() => handleLogout()}>
                <span className="dropdown-item">Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* mobile view */}

      <nav className="navbar bg-secondary d-sm-none d-block">
        <div className="container-fluid">
          <Link to="/">
            <img src="/assets/images/logo-nav-white.svg" alt="logo" className="w-75" />
          </Link>

          <div className="d-flex justify-content-between ">
            <span className="profile ms-auto d-flex justify-content-center align-items-center align-self-center mx-2">
              <img src="/assets/images/bell.svg" alt="notification" />
            </span>
            <div className="cursor-pointer text-white  d-flex justify-content-center align-items-center align-self-center mx-2">
              <small className="profile d-flex me-2 justify-content-center align-items-center align-self-center text-secondary fw-medium">
                {user?.first_name?.charAt(0)}
                {user?.last_name?.charAt(0)}
              </small>
            </div>
            <button
              className="navbar-toggler border-0 p-0 shadow-none profile  d-flex justify-content-center align-items-center align-self-center mx-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <iconify-icon icon="gg:menu-left-alt" className="profile ms-auto  fs-1 mt-2"></iconify-icon>
            </button>
          </div>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                {/* <img src="./images/logo.svg" className="w-75" /> */}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
