import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const vendorPriceListApi = createApi({
  reducerPath: 'vendorPriceListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getFiles: builder.mutation({
      query: (params) => ({
        url: 'project/s3/get-files',
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteVendorPriceList: builder.mutation({
      query: (body) => ({
        url: `project/s3/delete-files`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateVendorPriceList: builder.mutation({
      query: () => ({
        url: `project/s3/update-file`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createVendorPriceList: builder.mutation({
      query: (body) => ({
        url: `project/s3/save-file`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPresignedURL: builder.mutation({
      query: (params) => ({
        url: `project/s3/put-presigned-url`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPresignedURLPreView: builder.mutation({
      query: (params) => ({
        url: `project/s3/get-presigned-url`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetFilesMutation,
  useDeleteVendorPriceListMutation,
  useUpdateVendorPriceListMutation,
  useCreateVendorPriceListMutation,
  useGetPresignedURLMutation,
  useGetPresignedURLPreViewMutation,
} = vendorPriceListApi;
