import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <div className="d-flex position-fixed overflow-auto h-100 w-100 login-bg">
      <div className="flex-grow-1 text-white p-md-5 d-flex align-items-center overflow-y-hidden">
        <div className="align-self-center w-100">
          <div className="d-table m-auto login-bx">
            <div className="bg-white py-4 px-md-5 px-4 text-dark">
              <div className="text-center mb-3">
                <img src="/assets/images/logo-bg.svg" alt="" />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
