import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const mailServiceApi = createApi({
  reducerPath: 'mailServiceApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMailTemplate: builder.mutation({
      query: (params) => ({
        url: `notification/emailtemplate`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMergeTags: builder.mutation({
      query: (id) => ({
        url: `notification/tags/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getmailTemplateById: builder.mutation({
      query: (id) => ({
        url: `notification/emailtemplate/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateMailTemplate: builder.mutation({
      query: ({ body, id }) => ({
        url: `notification/emailtemplate/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetMailTemplateMutation,
  useGetmailTemplateByIdMutation,
  useGetMergeTagsMutation,
  useUpdateMailTemplateMutation,
} = mailServiceApi;
