import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { setUserPermission } from '../features/userSlice';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getCountries: builder.mutation({
      query: () => ({
        url: `project/places/countries?findAll=1`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getProjectCountries: builder.mutation({
      query: (project_id) => ({
        url: `project/product/countries/${project_id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getStates: builder.mutation({
      query: (country_id) => ({
        url: `project/places/states/${country_id}?findAll=1`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getCities: builder.mutation({
      query: (state_id) => ({
        url: `project/places/cities/${state_id}?findAll=1`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPages: builder.mutation({
      query: () => ({
        url: `auth/rbac/pages`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}

        try {
          const { data } = await queryFulfilled;

          dispatch(setUserPermission(data.permissions));
        } catch (error) {}
      },
    }),
    getAllClient: builder.mutation({
      query: () => ({
        url: `client/all`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getPutUrl: builder.mutation({
      query: (params) => ({
        url: `project/document/putUrl`,
        method: 'GET',
        params,
      }),
    }),
    getAllRegions: builder.mutation({
      query: (params) => ({
        url: `project/places/regions`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createRegion: builder.mutation({
      query: (body) => ({
        url: `project/places/regions`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getAllProcess: builder.mutation({
      query: (params) => ({
        url: `project/process`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createProcess: builder.mutation({
      query: (body) => ({
        url: `project/process`,
        method: 'POST',
        body,
      }),
    }),
    saveDocumentInfo: builder.mutation({
      query: (body) => ({
        url: `project/document/saveDocInfo`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getProjectPresignedURLPreView: builder.mutation({
      query: (params) => ({
        url: `project/s3/get-s3-presigned-url`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    deleteAttachment: builder.mutation({
      query: (body) => ({
        url: `project/document/deleteDocInfo`,
        method: 'DELETE',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMetrices: builder.mutation({
      query: (params) => ({
        url: `project/dashboard/metrices`,
        method: 'GET',
        params,
      }),
    }),
    getNotificationList: builder.mutation({
      query: (params) => ({
        url: `user/notifications`,
        method: 'GET',
        params,
      }),
    }),
    updateNotificationStatus: builder.mutation({
      query: (body) => ({
        url: `user/notifications`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getNotificationConfig: builder.mutation({
      query: (params) => ({
        url: `notification/config`,
        method: 'GET',
        params,
      }),
    }),
    updateNotificationConfig: builder.mutation({
      query: (body) => ({
        url: `notification/config`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateBankDetails: builder.mutation({
      query: (body) => ({
        url: `user/bankDetails`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getBankDetails: builder.mutation({
      query: (params) => ({
        url: `user/bankDetails`,
        method: 'GET',
        params,
      }),
    }),
    //empty data
    getEmptyData: builder.mutation(),
  }),
});

export const {
  useGetCountriesMutation,
  useGetProjectCountriesMutation,
  useGetStatesMutation,
  useGetCitiesMutation,
  useGetPagesMutation,
  useGetAllClientMutation,
  useGetPutUrlMutation,
  useSaveDocumentInfoMutation,
  useGetProjectPresignedURLPreViewMutation,
  useDeleteAttachmentMutation,
  useGetAllRegionsMutation,
  useCreateProcessMutation,
  useGetAllProcessMutation,
  useGetEmptyDataMutation,
  useGetMetricesMutation,
  useGetNotificationListMutation,
  useUpdateNotificationStatusMutation,
  useGetNotificationConfigMutation,
  useUpdateNotificationConfigMutation,
  useUpdateBankDetailsMutation,
  useGetBankDetailsMutation,
  useCreateRegionMutation,
} = generalApi;
