import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const priceListApi = createApi({
  reducerPath: 'priceListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getPriceList: builder.mutation({
      query: (params) => ({
        url: `project/expense/mfg`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPriceListById: builder.mutation({
      query: (id) => ({
        url: `project/expense/mfg/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postPriceList: builder.mutation({
      query: (body) => ({
        url: `project/expense/mfg`,
        method: 'POST',
        body,
      }),
    }),
    updatePriceList: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/expense/mfg/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetPriceListMutation, useGetPriceListByIdMutation, usePostPriceListMutation, useUpdatePriceListMutation } =
  priceListApi;
