function CloseNavBar() {
  var element = document.getElementsByClassName('collapse');
  var icon = document.getElementsByClassName('icon-close');

  if (element) {
    for (var i = 0; i < element.length; i++) {
      if (!element[i].classList.contains('accordion-collapse')) {
        element[i].classList.remove('show');
      }
    }
  }

  if (icon) {
    for (var i1 = 0; i1 < icon.length; i1++) {
      icon[i1].setAttribute('aria-expanded', 'false');
    }
  }
}

export default CloseNavBar;
