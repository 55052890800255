import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetPagesMutation } from '../../redux/api/generalApi';
import CloseNavBar from './NavBarAction';
import { currentUser } from '../../redux/features/userSlice';
import { useSelector } from 'react-redux';

function Sidebar() {
  const [pages, setPages] = useState([]);
  const user = useSelector(currentUser);
  const [getPages, { isLoading, isError, isSuccess, data }] = useGetPagesMutation();
  useEffect(() => {
    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isSuccess) {
      setPages(data.data);
    }
    if (isError) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const renderSubMenu = (item) => {
    return (
      <div className="collapse" id={item.parent_id + '-collapse'} key={item.page_id}>
        <ul className="btn-toggle-nav fw-medium ms-5">
          <li>
            <Link to={item.url} id="menu" className="d-flex text-decoration-none text-white menus">
              {item.label}
            </Link>
          </li>
        </ul>
      </div>
    );
  };
  const renderMenuItem = (item) => {
    if (item.children && item.children.length > 0) {
      return (
        <>
          <Link
            to={item.url}
            className="d-flex btn-toggle w-100 align-items-center collapsed icon-close"
            data-bs-toggle="collapse"
            data-bs-target={'#' + item.page_id + '-collapse'}
            aria-expanded="false"
            id={'collapsed'}
          >
            <span className="sidenav-icon bg-white rounded-circle d-flex justify-content-center align-items-center align-self-center">
              <iconify-icon icon={item.icon} width="24" className="text-secondary"></iconify-icon>
            </span>
            <span className="nav-text text-white mx-3 fs-6">{item.label}</span>
          </Link>
          {item.children.map((child) => renderSubMenu(child))}
        </>
      );
    } else {
      return (
        <Link to={item.url} className="d-flex w-100 align-items-center">
          <span className="sidenav-icon bg-white rounded-circle d-flex justify-content-center align-items-center align-self-center">
            {item.icon ? (
              <iconify-icon icon={item.icon} width="24" className="text-secondary"></iconify-icon>
            ) : (
              <img src={item.img} width="24" alt="T&C" className="text-secondary"></img>
            )}
          </span>
          <span className="nav-text text-white mx-3 fs-6">{item.label}</span>
        </Link>
      );
    }
  };

  return (
    <>
      <div className="bg-secondary d-sm-block d-none sidebar" onMouseLeave={CloseNavBar}>
        <div id="navigation" className="navigation nav-collapsed">
          <div className="nav-content d-flex flex-column bg-secondary">
            <div className="nav-header bg-white p-3">
              <div className="logo">
                <Link to={'/'}>
                  <img src="/assets/images/logo.svg" alt="logo name" height={42} className="logo-nav" />
                  <img src="/assets/images/logo-nav.svg" alt="logo" className="logo-icon" />
                </Link>
              </div>
            </div>
            <div className="main-nav">
              <nav className="navbar navbar-expand pt-0">
                <div className="collapse navbar-collapse" onMouseLeave={CloseNavBar} id="navbarTogglerDemo02">
                  <ul className="navbar-nav d-flex flex-column flex-fill overflow-auto">
                    {!isLoading && (
                      <li className="py-2">
                        {renderMenuItem({
                          page_id: 1,
                          label: 'Dashboard',
                          icon: 'bxs:dashboard',
                          url: '/',
                          order_index: 1,
                          parent_id: null,
                        })}
                      </li>
                    )}
                    {pages.map((item) => {
                      return (
                        <li className="py-2" key={item.page_id}>
                          {renderMenuItem(item)}
                        </li>
                      );
                    })}
                    {!isLoading && user?.role_id === 3 && (
                      <li className="py-2">
                        {renderMenuItem({
                          page_id: 999,
                          label: 'Terms & Conditions',
                          img: '/assets/images/terms.svg',
                          url: '/client/termsandconditions',
                          order_index: 999,
                          parent_id: null,
                        })}
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <nav className="navbar bg-secondary d-sm-none d-block">
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <Link to={'/'} id="menu">
                <img src="/assets/images/logo.svg" alt="logo name" className="logo-nav" />
              </Link>
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body bg-secondary">
            <div className="main-nav">
              <nav className="navbar navbar-expand pt-0">
                <div className="collapse navbar-collapse" onMouseLeave={CloseNavBar} id="navbarToggler">
                  <ul className="navbar-nav d-flex flex-column flex-fill overflow-auto">
                    {!isLoading && (
                      <li className="py-2" id="menu">
                        {renderMenuItem({
                          page_id: 1,
                          label: 'Dashboard',
                          icon: 'bxs:dashboard',
                          url: '/',
                          order_index: 1,
                          parent_id: null,
                        })}
                      </li>
                    )}
                    {pages.map((item) => {
                      return (
                        <li className="py-2" key={item.page_id}>
                          {renderMenuItem(item)}
                        </li>
                      );
                    })}
                    {!isLoading && user?.role_id === 3 && (
                      <li className="py-2">
                        {renderMenuItem({
                          page_id: 999,
                          label: 'Terms & Conditions',
                          img: '/assets/images/terms.svg',
                          url: '/client/termsandconditions',
                          order_index: 999,
                          parent_id: null,
                        })}
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
