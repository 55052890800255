import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getRoles: builder.mutation({
      query: ({ sortBy = 'role_name:asc', ...params }) => ({
        url: `auth/rbac/role?sortBy=${sortBy}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPermissions: builder.mutation({
      query: ({ role_id = 2, page = 1, size = 10, sortBy = 'created_at:asc' }) => ({
        url: `auth/rbac/permission?page=${page}&size=${size}&sortBy=${sortBy}&role_id=${role_id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updatePermissions: builder.mutation({
      query: ({ permissionId, body }) => ({
        url: `auth/rbac/permission/${permissionId}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetRolesMutation, useGetPermissionsMutation, useUpdatePermissionsMutation } = roleApi;
