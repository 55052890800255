import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const trackerApi = createApi({
  reducerPath: 'trackerApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getinvoice: builder.mutation({
      query: ({ sortBy = 'created_at:asc', id, ...params }) => ({
        url: `/project/tracker/invoice/${id}?sortBy=${sortBy}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `/project/tracker/invoice/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getrenewal: builder.mutation({
      query: ({ sortBy = 'created_at:asc', id, ...params }) => ({
        url: `/project/tracker/renewal/${id}?sortBy=${sortBy}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetrenewalMutation, useGetinvoiceMutation, useUpdateInvoiceMutation } = trackerApi;
