import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const inboundClientRequestApi = createApi({
  reducerPath: 'inboundClientRequestApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getInbound: builder.mutation({
      query: (params) => ({
        url: `project/inbound`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getQuotedInbound: builder.mutation({
      query: (params) => ({
        url: `project/inbound/quoted`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getInboundById: builder.mutation({
      query: (id) => ({
        url: `project/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    requestInbound: builder.mutation({
      query: (body) => ({
        url: `project/product`,
        method: 'POST',
        body,
      }),
    }),
    UpdaterequestInbound: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/product/${id}`,
        method: 'PATCH',
        body,
      }),
    }),

    rejectInbound: builder.mutation({
      query: (body) => ({
        url: `project/reject`,
        method: 'POST',
        body,
      }),
    }),
    approveInbound: builder.mutation({
      query: (body) => ({
        url: `project/approve`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetInboundMutation,
  useGetQuotedInboundMutation,
  useGetInboundByIdMutation,
  useRejectInboundMutation,
  useApproveInboundMutation,
  useRequestInboundMutation,
  useUpdaterequestInboundMutation,
} = inboundClientRequestApi;
