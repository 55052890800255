import { createApi } from '@reduxjs/toolkit/query/react';
import { logout, setUser } from '../features/userSlice';
import customFetchBase from './customFetchBase';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.data));
        } catch (error) {}
      },
    }),
    logoutUser: builder.mutation({
      query: (body) => ({
        url: 'auth/logout',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(logout());
        } catch (error) {}
      },
    }),
    verifyOTP: builder.mutation({
      query: (body) => ({
        url: `auth/verify-otp`,
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `auth/forgot-password`,
        method: 'POST',
        body: { email: body.email },
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `auth/change-password`,
        method: 'POST',
        body,
      }),
    }),
    changeTemporaryPassword: builder.mutation({
      query: (body) => ({
        url: `auth/change-temp-password`,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, newPassword, confirmNewPassword }) => ({
        url: `auth/reset-password?token=${token}`,
        method: 'POST',
        body: { newPassword, confirmNewPassword },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useChangePasswordMutation,
  useChangeTemporaryPasswordMutation,
  useResetPasswordMutation,
} = authApi;
