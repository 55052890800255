import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const labPriceListApi = createApi({
  reducerPath: 'labPriceListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getLabPriceList: builder.mutation({
      query: (params) => ({
        url: `project/expense/lab`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getLabPriceListById: builder.mutation({
      query: (id) => ({
        url: `project/expense/lab/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postLabPriceList: builder.mutation({
      query: (body) => ({
        url: `project/expense/lab`,
        method: 'POST',
        body,
      }),
    }),
    updateLabPriceList: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/expense/lab/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetLabPriceListMutation,
  useGetLabPriceListByIdMutation,
  usePostLabPriceListMutation,
  useUpdateLabPriceListMutation,
} = labPriceListApi;
