import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const emailTemplateMailchimpApi = createApi({
  reducerPath: 'emailTemplateMailchimpApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMailchimpTemplates: builder.mutation({
      query: (params) => ({
        url: `notification/mailchimp/templates`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMailchimpTemplateById: builder.mutation({
      query: (id) => ({
        url: `notification/mailchimp/templates/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postMailchimpTemplates: builder.mutation({
      query: (body) => ({
        url: `notification/mailchimp/templates`,
        method: 'POST',
        body,
      }),
    }),
    updateMailchimpTemplates: builder.mutation({
      query: (body) => ({
        url: `/notification/mailchimp/templates`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetMailchimpTemplatesMutation,
  useGetMailchimpTemplateByIdMutation,
  usePostMailchimpTemplatesMutation,
  useUpdateMailchimpTemplatesMutation,
} = emailTemplateMailchimpApi;
