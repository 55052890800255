import { motion } from 'framer-motion';

export default function LoadingScreen() {
  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          left: 0,
          zIndex: 9999,
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ rotateY: 0 }}
          animate={{ rotateY: 360 }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <img height={64} width={64} alt="logo" src="/assets/images/logo-nav.svg" />
        </motion.div>

        <div
          style={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border: `solid 3px #00C9E5`,
          }}
        >
          <motion.div
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
          ></motion.div>
        </div>
        <div
          style={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border: `solid 3px #0040E2`,
          }}
        >
          <motion.div
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
          ></motion.div>
        </div>
      </div>
    </>
  );
}
