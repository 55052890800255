import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const vendorApi = createApi({
  reducerPath: 'vendorApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postVendor: builder.mutation({
      query: (body) => ({
        url: `vendor`,
        method: 'POST',
        body,
      }),
    }),
    getVendor: builder.mutation({
      query: ({ sortBy = 'created_at:asc', findAll = 0, ...params }) => ({
        url: `vendor?sortBy=${sortBy}&findAll=${findAll}`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getVendorById: builder.mutation({
      query: (vendor_id) => ({
        url: `vendor/${vendor_id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getAllVendor: builder.mutation({
      query: () => ({
        url: `vendor/all`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateInlineVendor: builder.mutation({
      query: ({ body, vendor_id }) => ({
        url: `vendor/inline/${vendor_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateVendor: builder.mutation({
      query: ({ body, vendor_id }) => ({
        url: `vendor/${vendor_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    enableVendor: builder.mutation({
      query: (vendor_id) => ({
        url: `vendor/enable/${vendor_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    disableVendor: builder.mutation({
      query: (vendor_id) => ({
        url: `vendor/disable/${vendor_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    deleteVendor: builder.mutation({
      query: (vendor_id) => ({
        url: `vendor/delete/${vendor_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
  }),
});

export const {
  useGetVendorMutation,
  useGetVendorByIdMutation,
  useGetAllVendorMutation,
  usePostVendorMutation,
  useUpdateVendorMutation,
  useEnableVendorMutation,
  useDisableVendorMutation,
  useDeleteVendorMutation,
  useUpdateInlineVendorMutation,
} = vendorApi;
