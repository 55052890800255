export const UPDATE = 'update';
export const CREATE = 'create';
export const READ = 'list';
export const ROOT_FOLDER = 1;

export const UNAUTH = 401;
export const FORBIDDEN = 403;

export const ADMIN = 'ADMIN';
export const CLIENT = 'CLIENT';
export const CPM = 'CPM';
export const VENDOR = 'VENDOR';

export const allRoles = [
  {
    role_id: 1,
    role_name: 'ADMIN',
  },

  {
    role_id: 2,
    role_name: 'CPM',
  },

  {
    role_id: 3,
    role_name: 'CLIENT',
  },

  {
    role_id: 4,
    role_name: 'VENDOR',
  },
];
