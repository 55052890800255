import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getRecentProjects: builder.mutation({
      query: (params) => ({
        url: `project/dashboard/recentprojects`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getLateProjects: builder.mutation({
      query: (params) => ({
        url: `project/dashboard/lateprojects`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getClosingProjects: builder.mutation({
      query: (params) => ({
        url: `project/dashboard/closingprojects`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetClosingProjectsMutation, useGetLateProjectsMutation, useGetRecentProjectsMutation } = dashboardApi;
