import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getCampaign: builder.mutation({
      query: (params) => ({
        url: `notification/campaign`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMergeTags: builder.mutation({
      query: (params) => ({
        url: `notification/mailchimp/audience/mergetags`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getAudience: builder.mutation({
      query: (params) => ({
        url: `notification/mailchimp/audience`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getCampainById: builder.mutation({
      query: (id) => ({
        url: `notification/campaign/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    postCampaign: builder.mutation({
      query: (body) => ({
        url: `notification/campaign`,
        method: 'POST',
        body,
      }),
    }),
    sendCampaign: builder.mutation({
      query: (campaign_id) => ({
        url: `notification/campaign/${campaign_id}`,
        method: 'POST',
      }),
    }),
    updateCampaign: builder.mutation({
      query: ({ body, campaign_id }) => ({
        url: `notification/campaign/${campaign_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteCampaign: builder.mutation({
      query: (id) => ({
        url: `notification/campaign/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    resendCampaign: builder.mutation({
      query: (id) => ({
        url: `notification/campaign/${id}/createresend`,
        method: 'POST',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    replicateCampaign: builder.mutation({
      query: (id) => ({
        url: `notification/campaign/${id}/replicate`,
        method: 'POST',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    cancelCampaign: builder.mutation({
      query: (id) => ({
        url: `notification/campaign/${id}/cancelsend`,
        method: 'POST',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetCampaignMutation,
  useGetAudienceMutation,
  usePostCampaignMutation,
  useGetCampainByIdMutation,
  useUpdateCampaignMutation,
  useGetMergeTagsMutation,
  useDeleteCampaignMutation,
  useSendCampaignMutation,
  useResendCampaignMutation,
  useReplicateCampaignMutation,
  useCancelCampaignMutation,
} = campaignApi;
