import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const qboStatusApi = createApi({
  reducerPath: 'qboStatusApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getQbo: builder.mutation({
      query: () => ({
        url: 'auth/qbo/checkstatus',
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    disconnectQbo: builder.mutation({
      query: () => ({
        url: 'auth/qbo/disconnect',
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetQboMutation, useDisconnectQboMutation } = qboStatusApi;
