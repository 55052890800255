import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import ModuleGuard from '../guards/ModuleGuard';
import AuthLayout from '../layouts/AuthLayout';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import LoadingScreen from '../components/LoadingScreen';
import { CREATE, READ, UPDATE } from '../constants/constants';
import {
  AUTORITY,
  BONUS_LIST,
  CAMPAIGN,
  CLIENT,
  COUNTRY_SERVICE,
  DOCUMENT_CHECKLIST,
  EMPLOYEE,
  EXPENSE_LIST,
  GENERAL_SETTING,
  INBOUND_CLIENT_REQUEST,
  INTERNAL_RESOURCE,
  INVOICE_TRACKER,
  LAB_PRICE_LIST,
  MANAGE_EMAIL_TEMPLATES,
  MANAGE_PRODUCT_SERVICE,
  NEW_MFG_PRICE_LIST,
  PRICE_LIST,
  PRODUCT_LIST,
  PROJECT_LIST,
  RENEWAL_TRACKER,
  ROLE,
  SERVICE_CATEGORY,
  UNCONFIRMED_BILLS,
  VENDOR,
  VENDOR_PRICE_LIST,
} from '../constants/ModuleName';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'change-password',
          element: (
            <AuthGuard>
              <ChangePassword />
            </AuthGuard>
          ),
        },
        {
          path: 'change-temp-password',
          element: <ChangeTemporaryPassword />,
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          ),
        },
        {
          path: 'client/termsandconditions',
          element: (
            <AuthGuard>
              <ClientTerms />
            </AuthGuard>
          ),
        },

        {
          path: '/profile',
          element: (
            <AuthGuard>
              <Profile />
            </AuthGuard>
          ),
        },

        {
          path: '/project',
          children: [
            {
              path: '/project/tracker',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PROJECT_LIST}>
                    <ProjectList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/tracker/invoiced',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PROJECT_LIST}>
                    <ProjectList isInvoiced={true} />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/details/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PROJECT_LIST}>
                    <ProjectDetails />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={PROJECT_LIST}>
                    <ProjectListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={PROJECT_LIST}>
                    <ProjectListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },

            {
              path: '/project/pricelist/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PRICE_LIST}>
                    <PriceListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/pricelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PRICE_LIST}>
                    <PriceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/pricelist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={PRICE_LIST}>
                    <PriceListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/labpricelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={LAB_PRICE_LIST}>
                    <LabPriceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/labpricelist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={LAB_PRICE_LIST}>
                    <LabPriceListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/labpricelist/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={LAB_PRICE_LIST}>
                    <LabPriceListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/newmfgpricelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={NEW_MFG_PRICE_LIST}>
                    <NewMFGPriceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/newmfgpricelist/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={NEW_MFG_PRICE_LIST}>
                    <NewMFGPriceListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/vendorpricelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={VENDOR_PRICE_LIST}>
                    <VendorPriceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/expensepricelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={EXPENSE_LIST}>
                    <ExpensePriceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/expensepricelist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={EXPENSE_LIST}>
                    <ExpenseForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/expensepricelist/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={EXPENSE_LIST}>
                    <ExpenseForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/expensepricelist/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={EXPENSE_LIST}>
                    <ExpenseForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/bonuslist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={BONUS_LIST}>
                    <BonusList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/bonuslist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={BONUS_LIST}>
                    <BonusForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/bonuslist/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={BONUS_LIST}>
                    <BonusForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/invoicetracker',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={INVOICE_TRACKER}>
                    <InvoiceTracker />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/renewaltracker',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={RENEWAL_TRACKER}>
                    <RenewalTracker />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/inboundclientrequest',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={INBOUND_CLIENT_REQUEST}>
                    <InboundClientRequest />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/inboundclientrequest/quoted',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={INBOUND_CLIENT_REQUEST}>
                    <InboundClientRequest isQuoted={true} />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/inbounddetail/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={INBOUND_CLIENT_REQUEST}>
                    <InboundDetails />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/productlist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PRODUCT_LIST}>
                    <ProductList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/productdetails/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={PRODUCT_LIST}>
                    <ProductDetails />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/project/unconfirmedbills',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={UNCONFIRMED_BILLS}>
                    <UnConfirmedBillsList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },

        {
          path: '/settings',
          children: [
            {
              path: '/settings/general',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={GENERAL_SETTING}>
                    <GeneralSettings />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/emailtemplates',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={MANAGE_EMAIL_TEMPLATES}>
                    <EmailService />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/emailtemplates/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={MANAGE_EMAIL_TEMPLATES}>
                    <EmailServiceForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/documentchecklist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={DOCUMENT_CHECKLIST}>
                    <DocumentCheckList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/documentchecklist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={DOCUMENT_CHECKLIST}>
                    <DocumentCheckListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/documentchecklist/edit',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={DOCUMENT_CHECKLIST}>
                    <DocumentCheckListForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/internalresourcelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={INTERNAL_RESOURCE}>
                    <InternalResourceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/internalresourcelist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={INTERNAL_RESOURCE}>
                    <InternalResourceForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/internalresourcelist/edit',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={INTERNAL_RESOURCE}>
                    <InternalResourceForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/countryservice',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={COUNTRY_SERVICE}>
                    <CountryServiceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/servicecategory',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={SERVICE_CATEGORY}>
                    <ServiceCategoryList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/authority',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={AUTORITY}>
                    <AuthorityList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/countryservicedetails',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={COUNTRY_SERVICE}>
                    <CountryServiceDetailsList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/authoritydetails',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={AUTORITY}>
                    <AuthorityDetailsList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/manageproductservices',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={MANAGE_PRODUCT_SERVICE}>
                    <ProductServiceList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/manageproductservices/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={MANAGE_PRODUCT_SERVICE}>
                    <ProductServiceForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/settings/mappingproductservices',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={MANAGE_PRODUCT_SERVICE}>
                    <ProductServiceForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/client',

          children: [
            {
              path: '/client/list',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={CLIENT}>
                    <ClientList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/client/prospectivelist',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={CLIENT}>
                    <ClientProspectiveList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/client',
          children: [
            {
              path: '/client/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={CLIENT}>
                    <ClientForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/client',
          children: [
            {
              path: '/client/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={CLIENT}>
                    <ClientForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/client',
          children: [
            {
              path: '/client/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={CLIENT}>
                    <ClientForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/prospectivelist',
          children: [
            {
              path: '/prospectivelist/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={CLIENT}>
                    <ClientProspectiveForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/prospectivelist',
          children: [
            {
              path: '/prospectivelist/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={CLIENT}>
                    <ClientProspectiveForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/vendor',
          children: [
            {
              path: '/vendor/list',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={VENDOR}>
                    <VendorList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/vendor/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={VENDOR}>
                    <VendorForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/vendor/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={VENDOR}>
                    <VendorForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/vendor/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={VENDOR}>
                    <VendorForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/employee',
          children: [
            {
              path: '/employee/list',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={EMPLOYEE}>
                    <EmployeeList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/employee/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={EMPLOYEE}>
                    <EmployeeForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/employee/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={EMPLOYEE}>
                    <EmployeeForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/employee/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={EMPLOYEE}>
                    <EmployeeForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/employee/reports',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={'Employee Reports'}>
                    <EmployeeReport />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/marketingmanagement',
          children: [
            {
              path: '/marketingmanagement/campaign',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={CAMPAIGN}>
                    <Campaign />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/marketingmanagement/campaign/create',
              element: (
                <AuthGuard>
                  <ModuleGuard page={CREATE} moduleName={CAMPAIGN}>
                    <CampaignForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/marketingmanagement/campaign/edit/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={CAMPAIGN}>
                    <CampaignForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
            {
              path: '/marketingmanagement/campaign/view/:id',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={CAMPAIGN}>
                    <CampaignForm />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: '/role',
          children: [
            {
              path: '/role/list',
              element: (
                <AuthGuard>
                  <ModuleGuard page={READ} moduleName={ROLE}>
                    <RoleList />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },

            {
              path: '/role/managepermission',
              element: (
                <AuthGuard>
                  <ModuleGuard page={UPDATE} moduleName={ROLE}>
                    <ManagePermissions />
                  </ModuleGuard>
                </AuthGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      children: [{ path: '/forbidden', element: <Forbidden /> }],
    },
    {
      path: '*',
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
// Import components

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const ClientTerms = Loadable(lazy(() => import('../pages/terms/ClientTerms')));
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));
const RoleList = Loadable(lazy(() => import('../pages/rolemanagement/RoleList')));
const ManagePermissions = Loadable(lazy(() => import('../pages/rolemanagement/ManagePermissions')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Forbidden = Loadable(lazy(() => import('../pages/Page403')));
const GeneralSettings = Loadable(lazy(() => import('../pages/settings/GeneralSettings')));
const EmailService = Loadable(lazy(() => import('../pages/settings/EmailService')));
const EmailServiceForm = Loadable(lazy(() => import('../pages/settings/EmailServiceForm')));
const Campaign = Loadable(lazy(() => import('../pages/marketingmanagement/Campaign')));
const CampaignForm = Loadable(lazy(() => import('../pages/marketingmanagement/CampaignForm')));

const DocumentCheckList = Loadable(lazy(() => import('../pages/settings/DocumentCheckList')));
const CountryServiceList = Loadable(lazy(() => import('../pages/settings/CountryServiceList')));
const AuthorityList = Loadable(lazy(() => import('../pages/settings/AuthorityList')));
const CountryServiceDetailsList = Loadable(lazy(() => import('../pages/settings/CountryServiceDetailsList')));
const AuthorityDetailsList = Loadable(lazy(() => import('../pages/settings/AuthorityDetailsList')));
const ServiceCategoryList = Loadable(lazy(() => import('../pages/settings/ServiceCategoryList')));
const BonusList = Loadable(lazy(() => import('../pages/projectmanagement/BonusList')));
const ExpensePriceList = Loadable(lazy(() => import('../pages/projectmanagement/ExpensePriceList')));
const InboundClientRequest = Loadable(lazy(() => import('../pages/projectmanagement/InboundClientRequest')));
const InvoiceTracker = Loadable(lazy(() => import('../pages/projectmanagement/InvoiceTracker')));
const LabPriceList = Loadable(lazy(() => import('../pages/projectmanagement/LabPriceList')));
const PriceList = Loadable(lazy(() => import('../pages/projectmanagement/PriceList')));
const NewMFGPriceList = Loadable(lazy(() => import('../pages/projectmanagement/NewMFGPriceList')));
const NewMFGPriceListForm = Loadable(lazy(() => import('../pages/projectmanagement/NewMFGPriceListForm')));
const ProjectList = Loadable(lazy(() => import('../pages/projectmanagement/ProjectList')));
const RenewalTracker = Loadable(lazy(() => import('../pages/projectmanagement/RenewalTracker')));
const VendorPriceList = Loadable(lazy(() => import('../pages/projectmanagement/VendorPriceList')));
const ClientList = Loadable(lazy(() => import('../pages/clientmanagement/ClientList')));
const ClientProspectiveList = Loadable(lazy(() => import('../pages/clientmanagement/ProspectiveClientList')));
const ClientProspectiveForm = Loadable(lazy(() => import('../pages/clientmanagement/ProspectiveClientForm')));
const VendorList = Loadable(lazy(() => import('../pages/vendormanagement/VendorList')));
const EmployeeList = Loadable(lazy(() => import('../pages/employeemanagement/EmployeeList')));
const EmployeeForm = Loadable(lazy(() => import('../pages/employeemanagement/EmployeeForm')));
const EmployeeReport = Loadable(lazy(() => import('../pages/employeemanagement/EmployeeReport')));
const ChangeTemporaryPassword = Loadable(lazy(() => import('../pages/authentication/ChangeTemporaryPassword')));
const VendorForm = Loadable(lazy(() => import('../pages/vendormanagement/VendorForm')));
const ClientForm = Loadable(lazy(() => import('../pages/clientmanagement/ClientForm')));
const BonusForm = Loadable(lazy(() => import('../pages/projectmanagement/BonusForm')));
const PriceListForm = Loadable(lazy(() => import('../pages/projectmanagement/PriceListForm')));
const LabPriceListForm = Loadable(lazy(() => import('../pages/projectmanagement/LabPriceListForm')));
const ExpenseForm = Loadable(lazy(() => import('../pages/projectmanagement/ExpenseForm')));
const ProjectListForm = Loadable(lazy(() => import('../pages/projectmanagement/ProjectListForm')));
const ProjectDetails = Loadable(lazy(() => import('../pages/projectmanagement/ProjectDetails')));
const ProductList = Loadable(lazy(() => import('../pages/projectmanagement/ProductList')));
const ProductDetails = Loadable(lazy(() => import('../pages/projectmanagement/ProductDetails')));
const InboundDetails = Loadable(lazy(() => import('../pages/projectmanagement/InboundDetails')));
const DocumentCheckListForm = Loadable(lazy(() => import('../pages/settings/DocumentCheckListForm')));
const InternalResourceList = Loadable(lazy(() => import('../pages/settings/InternalResourceList')));
const InternalResourceForm = Loadable(lazy(() => import('../pages/settings/InternalResourceForm')));
const UnConfirmedBillsList = Loadable(lazy(() => import('../pages/projectmanagement/UnConfirmedBillsList')));
const ProductServiceList = Loadable(lazy(() => import('../pages/settings/ProductServiceList')));
const ProductServiceForm = Loadable(lazy(() => import('../pages/settings/ProductServiceForm')));
