import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from './api/authApi';
import { employeeApi } from './api/employeeApi';
import { dashboardApi } from './api/dashboardApi';
import { roleApi } from './api/roleApi';
import { settingsApi } from './api/settingsApi';
import { userApi } from './api/userApi';
import { generalApi } from './api/generalApi';
import { vendorApi } from './api/vendorApi';
import userReducer from './features/userSlice';
import { qboStatusApi } from './api/qboStatusApi';
import { clientApi } from './api/clientApi';
import { bonusListApi } from './api/bonusListApi';
import { priceListApi } from './api/priceListApi';
import { labPriceListApi } from './api/labPriceListApi';
import { expenseListApi } from './api/expenseListApi';
import { vendorPriceListApi } from './api/vendorPriceListApi';
import { projectListApi } from './api/projectListApi';
import { technicalSpecificationApi } from './api/technicalSpecificationApi';
import { productApi } from './api/productApi';
import { inboundClientRequestApi } from './api/inboundClientRequestApi';
import { trackerApi } from './api/trackerApi';
import { mailServiceApi } from './api/mailServiceApi';
import { campaignApi } from './api/campaignApi';
import { emailTemplateMailchimpApi } from './api/emailTemplateMailchimpApi';
import { invoiceApi } from './api/invoiceApi';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [qboStatusApi.reducerPath]: qboStatusApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [priceListApi.reducerPath]: priceListApi.reducer,
    [labPriceListApi.reducerPath]: labPriceListApi.reducer,
    [expenseListApi.reducerPath]: expenseListApi.reducer,
    [bonusListApi.reducerPath]: bonusListApi.reducer,
    [vendorPriceListApi.reducerPath]: vendorPriceListApi.reducer,
    [projectListApi.reducerPath]: projectListApi.reducer,
    [technicalSpecificationApi.reducerPath]: technicalSpecificationApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [inboundClientRequestApi.reducerPath]: inboundClientRequestApi.reducer,
    [trackerApi.reducerPath]: trackerApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [mailServiceApi.reducerPath]: mailServiceApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [emailTemplateMailchimpApi.reducerPath]: emailTemplateMailchimpApi.reducer,

    userState: userReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      roleApi.middleware,
      settingsApi.middleware,
      employeeApi.middleware,
      vendorApi.middleware,
      generalApi.middleware,
      qboStatusApi.middleware,
      clientApi.middleware,
      bonusListApi.middleware,
      priceListApi.middleware,
      labPriceListApi.middleware,
      expenseListApi.middleware,
      vendorPriceListApi.middleware,
      projectListApi.middleware,
      technicalSpecificationApi.middleware,
      productApi.middleware,
      invoiceApi.middleware,
      inboundClientRequestApi.middleware,
      trackerApi.middleware,
      dashboardApi.middleware,
      mailServiceApi.middleware,
      campaignApi.middleware,
      emailTemplateMailchimpApi.middleware,
    ]),
});

export var RootState = store.getState;
export var AppDispatch = store.dispatch;
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;
