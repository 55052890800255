import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const employeeApi = createApi({
  reducerPath: 'employeeApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postEmployee: builder.mutation({
      query: (body) => ({
        url: `user/employee/cpm`,
        method: 'POST',
        body,
      }),
    }),
    getEmployee: builder.mutation({
      query: (params) => ({
        url: `user/employee/cpm`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getAllCPM: builder.mutation({
      query: () => ({
        url: `user/employee/cpm/all`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getEmployeeById: builder.mutation({
      query: (employeeId) => ({
        url: `user/employee/cpm/${employeeId}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateEmployee: builder.mutation({
      query: ({ body, cpm_id }) => ({
        url: `user/employee/cpm/${cpm_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    enableEmployee: builder.mutation({
      query: (cpm_id) => ({
        url: `user/employee/cpm/enable/${cpm_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    disableEmployee: builder.mutation({
      query: (cpm_id) => ({
        url: `user/employee/cpm/disable/${cpm_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    updateCpmRole: builder.mutation({
      query: ({ body, cpmId }) => ({
        url: `user/employee/cpm/role/${cpmId}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetEmployeeMutation,
  useGetEmployeeByIdMutation,
  useGetAllCPMMutation,
  usePostEmployeeMutation,
  useUpdateEmployeeMutation,
  useEnableEmployeeMutation,
  useDisableEmployeeMutation,
  useUpdateCpmRoleMutation,
} = employeeApi;
