import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const bonusListApi = createApi({
  reducerPath: 'bonusListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postBonusList: builder.mutation({
      query: (body) => ({
        url: `project/expense/bonus`,
        method: 'POST',
        body,
      }),
    }),
    getBonusList: builder.mutation({
      query: (params) => ({
        url: `project/expense/bonus`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getBonusListById: builder.mutation({
      query: (id) => ({
        url: `project/expense/bonus/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateBonusList: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/expense/bonus/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { usePostBonusListMutation, useGetBonusListByIdMutation, useGetBonusListMutation, useUpdateBonusListMutation } =
  bonusListApi;
