import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getQboService: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getServices`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getTerms: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getTerms`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createTerms: builder.mutation({
      query: (params) => ({
        url: `project/qbo/createTerm`,
        method: 'POST',
        params,
      }),
    }),
    getQboFileToken: builder.mutation({
      query: ({ qbo_id, is_quote }) => ({
        url: `project/qbo/getFileToken?qbo_id=${qbo_id}&is_quote=${is_quote}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getBill: builder.mutation({
      query: ({ id, is_quote }) => ({
        url: `project/qbo/getBill?size=200&page=1&sortBy=Id DESC&id=${id}&isQuote=${is_quote}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getExistingBill: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getBill`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    mapExistingBillToProject: builder.mutation({
      query: (body) => ({
        url: `project/qbo/mapExistingBillToProject`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getQboFile: builder.mutation({
      query: ({ url }) => ({
        url: `project/qbo/getFile/${url}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createQboService: builder.mutation({
      query: (body) => ({
        url: `project/qbo/createService`,
        method: 'POST',
        body,
      }),
    }),
    createBill: builder.mutation({
      query: (body) => ({
        url: `project/qbo/createBill`,
        method: 'POST',
        body,
      }),
    }),
    updateBill: builder.mutation({
      query: (body) => ({
        url: `project/qbo/updateBill`,
        method: 'POST',
        body,
      }),
    }),
    getCustomerType: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getCustomerTypes`,
        method: 'GET',
        params,
      }),
    }),
    getCustomFields: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getBillCustomFields`,
        method: 'GET',
        params,
      }),
    }),
    getEmployeeReport: builder.mutation({
      query: (body) => ({
        url: `project/qbo/report`,
        method: 'post',
        body,
      }),
    }),
    convertEstimateToInvoice: builder.mutation({
      query: (body) => ({
        url: `project/qbo/estimatetoinvoice`,
        method: 'POST',
        body,
      }),
    }),
    getQboServiceForProjects: builder.mutation({
      query: (body) => ({
        url: `project/qbo/getQboServiceForProjects`,
        method: 'POST',
        body,
      }),
    }),
    getProjectInfoFromQbo: builder.mutation({
      query: (body) => ({
        url: `project/qbo/getProjectInfoFromQbo`,
        method: 'POST',
        body,
      }),
    }),
    getUnConfirmedBill: builder.mutation({
      query: (params) => ({
        url: `project/qbo/getUnConfirmedBill`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteBill: builder.mutation({
      query: (body) => ({
        url: `project/qbo/deleteBill`,
        method: 'DELETE',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetQboServiceMutation,
  useGetQboFileTokenMutation,
  useGetBillMutation,
  useGetQboFileMutation,
  useCreateQboServiceMutation,
  useCreateTermsMutation,
  useCreateBillMutation,
  useUpdateBillMutation,
  useGetTermsMutation,
  useGetCustomerTypeMutation,
  useGetCustomFieldsMutation,
  useGetEmployeeReportMutation,
  useConvertEstimateToInvoiceMutation,
  useGetQboServiceForProjectsMutation,
  useGetProjectInfoFromQboMutation,
  useGetExistingBillMutation,
  useMapExistingBillToProjectMutation,
  useGetUnConfirmedBillMutation,
  useDeleteBillMutation,
} = invoiceApi;
