import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import CloseNavBar from './NavBarAction';

export default function DashboardLayout() {
  return (
    <div className="d-flex flex-row h-100">
      <Sidebar />
      <div className="d-flex flex-column flex-grow-1 w-100 h-100 overflow-auto">
        <Header />
        <div className="overflow-auto py-3 dashboard-layout" onFocus={CloseNavBar} onMouseOver={CloseNavBar}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
