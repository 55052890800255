import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const technicalSpecificationApi = createApi({
  reducerPath: 'technicalSpecificationApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getSpecification: builder.mutation({
      query: () => ({
        url: `project/product/technicalspecification?findAll=1`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postSpecification: builder.mutation({
      query: (body) => ({
        url: `project/product/technicalspecification`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetSpecificationMutation, usePostSpecificationMutation } = technicalSpecificationApi;
