import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { logout, updateUserToken } from '../features/userSlice';
import { FORBIDDEN, UNAUTH } from '../../constants/constants';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const { user } = getState().userState;
    if (user?.tokens?.access?.token) {
      headers.set('authorization', `Bearer ${user.tokens.access.token}`);
    }
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.data?.code === FORBIDDEN) {
    if (!mutex.isLocked()) {
      window.location.href = '/forbidden';
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (result.error?.data?.code === UNAUTH) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await fetch(`${baseUrl}auth/refresh-tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: api.getState().userState?.user?.tokens?.refresh.token,
          }),
        });
        let refreshedData = await refreshResult.json();
        if (refreshedData.data) {
          // Retry the initial query
          api.dispatch(updateUserToken(refreshedData.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
          window.location.href = '/auth/login';
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
