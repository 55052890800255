import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const projectListApi = createApi({
  reducerPath: 'projectListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getProjectList: builder.mutation({
      query: (params) => ({
        url: `/project`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getInvoicedProjectList: builder.mutation({
      query: (params) => ({
        url: `/project/invoiced`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getProjectById: builder.mutation({
      query: (project) => ({
        url: `/project/${project}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postProject: builder.mutation({
      query: (body) => ({
        url: `project`,
        method: 'POST',
        body,
      }),
    }),

    updateProjectList: builder.mutation({
      query: ({ body, project_id }) => ({
        url: `project/${project_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    copyProjectList: builder.mutation({
      query: ({ body, product_id }) => ({
        url: `project/addcountry/${product_id}`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    cloneProject: builder.mutation({
      query: ({ body, product_id }) => ({
        url: `project/cloneproject/${product_id}`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteProject: builder.mutation({
      query: ({ id }) => ({
        url: `project/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProjectListMutation,
  useGetInvoicedProjectListMutation,
  usePostProjectMutation,
  useGetProjectByIdMutation,
  useUpdateProjectListMutation,
  useCopyProjectListMutation,
  useCloneProjectMutation,
  useDeleteProjectMutation,
} = projectListApi;
