import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useModuleAccess from '../hooks/useModuleAccess';

import { Navigate } from 'react-router-dom';
import { CREATE, READ, UPDATE } from '../constants/constants';
import { DISABLE_ACCESS } from '../constants/permission';

ModuleGuard.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  moduleName: PropTypes.string,
};

export default function ModuleGuard({ children, page, moduleName }) {
  const { userPermission } = useModuleAccess(moduleName);
  const [moduleAccess, setModuleAccess] = useState();

  /* eslint-disable */
  const getPermission = () => {
    let flag = DISABLE_ACCESS;
    userPermission?.filter((data) => {
      if (page === READ) {
        flag = data?.can_read;
      }

      if (page === CREATE) {
        flag = data?.can_create;
      }

      if (page === UPDATE) {
        flag = data?.can_update;
      }
    });

    return flag;
  };
  /* eslint-enable  */

  useEffect(() => {
    const getFlag = getPermission();
    setModuleAccess(getFlag);
  }, [page, children]);

  if (moduleAccess === DISABLE_ACCESS) {
    return <Navigate to={'/forbidden'} />;
  } else {
    return <>{children}</>;
  }
}
