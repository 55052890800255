import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const expenseListApi = createApi({
  reducerPath: 'expenseApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getExpenseList: builder.mutation({
      query: (params) => ({
        url: `project/expense`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getExpenseListById: builder.mutation({
      query: (id) => ({
        url: `project/expense/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    postExpenseList: builder.mutation({
      query: (body) => ({
        url: `project/expense`,
        method: 'POST',
        body,
      }),
    }),
    updateExpenseList: builder.mutation({
      query: ({ body, id }) => ({
        url: `project/expense/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMFGPriceList: builder.mutation({
      query: (params) => ({
        url: `project/expense/newmfg`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMFGPriceListById: builder.mutation({
      query: (id) => ({
        url: `project/expense/newmfg/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetExpenseListMutation,
  useGetExpenseListByIdMutation,
  usePostExpenseListMutation,
  useUpdateExpenseListMutation,
  useGetMFGPriceListMutation,
  useGetMFGPriceListByIdMutation,
} = expenseListApi;
