import { createContext, useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = ({
  show,
  title,
  message,
  subMessage,
  onHide,
  onConfirm,
  icon,
  cancelLabel = 'Cancel',
  cancelClass = 'btn btn-light text-white',
  submitLabel = 'Submit',
  submitClass = 'btn btn-primary text-white',
  showSubmit = true,
}) => {
  return (
    <Modal show={show} onHide={onHide} size={'md'} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="text-center">
        {icon && (
          <div className={`alert-icon alert-${icon} alert-icon-show d-flex`}>
            <div class={`${icon}-icon-content`}>!</div>
          </div>
        )}
        <h2>{title}</h2>
        <div className="pt-4">
          <h5 className="m-0 fw-normal">{message}</h5>
          {subMessage && <p className="small mt-2 lh-1 text-black-50">{subMessage}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={cancelClass} onClick={onHide}>
          {cancelLabel}
        </Button>
        {showSubmit && (
          <Button className={submitClass} onClick={onConfirm}>
            {submitLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmationModalContext = createContext({});

const ConfirmationModalProvider = ({ children }) => {
  const [show, setShowModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});

  const showModal = ({
    title,
    message,
    subMessage,
    icon,
    actionCallback,
    cancelLabel,
    submitLabel,
    cancelClass,
    submitClass,
    showSubmit,
  }) => {
    setShowModal(true);
    setModalConfig({
      title,
      message,
      subMessage,
      icon,
      actionCallback,
      cancelLabel,
      submitLabel,
      cancelClass,
      submitClass,
      showSubmit,
    });
  };

  const resetModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setModalConfig({});
    }, 300);
  };

  const onConfirm = () => {
    modalConfig?.actionCallback(true);
    resetModal();
  };

  const onHide = () => {
    modalConfig?.actionCallback(false);
    resetModal();
  };

  return (
    <ConfirmationModalContext.Provider value={{ showModal }}>
      <ConfirmationModal
        show={show}
        title={modalConfig?.title}
        icon={modalConfig?.icon}
        message={modalConfig?.message}
        subMessage={modalConfig?.subMessage}
        cancelLabel={modalConfig?.cancelLabel}
        submitLabel={modalConfig?.submitLabel}
        cancelClass={modalConfig?.cancelClass}
        submitClass={modalConfig?.submitClass}
        showSubmit={modalConfig?.showSubmit}
        onConfirm={onConfirm}
        onHide={onHide}
      />
      {children}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModal = () => {
  const { showModal } = useContext(ConfirmationModalContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      showModal({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationModal;
export { ConfirmationModalProvider, useConfirmationModal };
